<template>
  <div class="classWork">
    <div class="chat-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`@/assets/image/course.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">职业规划课程体系</div>
      </div>
      <div class="head-right">
        <el-image
          class="backIcon"
          @click="sendExit"
          :src="require(`@/assets/image/exit2.png`)"
          fit="contain"
        ></el-image>
      </div>
    </div>

    <el-row class="search">
      <el-col :span="4">
        <el-row type="flex">
          <el-col :span="8">
            <el-image
              class="backIcon"
              @click="goBack"
              :src="require(`@/assets/image/backIcon.png`)"
              fit="contain"
            ></el-image
          ></el-col>
          <el-col :span="16">我的作业</el-col>
        </el-row>
      </el-col>
      <el-col :span="20" class="searchRadio">
        <el-radio-group v-model="searchRadio" size="medium">
          <el-radio :label="0">全部</el-radio>
          <el-radio :label="1">待批阅</el-radio>
          <el-radio :label="2">已批阅</el-radio>
          <el-radio :label="3">未完成</el-radio>
          <el-radio :label="4">未提交</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <div>
      <el-table :data="tableData" style="width: 100%" :border="true">
        <el-table-column label="作业名" prop="entrepot_name"> </el-table-column>
        <el-table-column label="班级" prop="class_title"> </el-table-column>
        <el-table-column label="截止时间">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{
              conversion(scope.row.end_time)
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="作业进度">
          <template slot-scope="scope"
            >{{ workPlanObj[scope.row.plan] }}
          </template>
        </el-table-column>
        <el-table-column label="分数">
          <template slot-scope="scope">
            <span v-show="scope.row.allot_count">查看问卷</span>
            <span>{{ scope.row.allot_count }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
              >打开</el-button
            >
            <el-button
              :disabled="scope.row.plan === 4"
              size="mini"
              type="warning"
              @click="handleSubmit(scope.row)"
              >提交</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getMyClassEntrepot, submitPlan } from "@/request/api";
export default {
  data() {
    return {
      searchRadio: 0,
      tableData: [],
      data: [],
      workPlanObj: {
        1: "未完成",
        2: "未提交",
        3: "待批阅",
        4: "已批阅",
      },
    };
  },
  watch: {
    searchRadio(value) {
      if (value) {
        this.tableData = this.data.filter((item) => {
          return item.plan === value;
        });
      } else {
        this.tableData = this.data;
      }
    },
  },
  methods: {
    goBack() {
      this.$router.push("courseChat");
    },
    sendExit() {
      this.$emit("update-value");
    },
    async onSubmitPlan(shop_user_class_entrepot_job_id) {
      const res = await submitPlan({
        shop_user_class_entrepot_job_id,
        isNew: true,
      });
      if (res.data.code === "1000") {
        this.$message({
          type: "success",
          message: "提交成功",
        });
        this.init();
      } else {
        this.$message({
          type: "error",
          message: res.data.msg,
        });
      }
    },
    async handleSubmit(item) {
      if (item.plan === 1) {
        this.$message({
          type: "warning",
          message: "请先完成作业并保存",
        });
        return;
      }

      this.$confirm("确认提交作业吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onSubmitPlan(item.shop_user_class_entrepot_job_id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleEdit(row) {
      console.log(row);
      this.$router.push({
        path: `/classWorkDetail`,
        query: {
          shop_user_class_entrepot_job_id: row.shop_user_class_entrepot_job_id,
          entrepot_name: row.entrepot_name,
        },
      });
    },
    init() {
      getMyClassEntrepot().then((res) => {
        console.log(res);
        if (res.data.code === "1000") {
          this.tableData = res.data.data;
          this.data = res.data.data;
        }
      });
    },

    conversion(time) {
      const date = new Date(time);
      const Y = date.getFullYear();
      const M = date.getMonth() + 1;
      const D = date.getDate();
      return Y + "-" + M + "-" + D;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.classWork {
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      display: flex;
    }
    .myWork {
      width: 90px;
      line-height: 26px;
      cursor: pointer;
      color: #2a6ee9;
      font-size: 16px;
    }
    .backIcon {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }

  .search {
    width: 100%;
    background: #fff;
    border-radius: 8px 8px 8px 8px;
    padding: 20px 40px;
    margin: 30px 0;
    line-height: 38px;
    // font-size: 18px;
    .backIcon {
      width: 34px;
      height: 34px;
      cursor: pointer;
    }
    .searchRadio {
      font-size: 18px;
    }
  }
}
</style>
